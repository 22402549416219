var Aneox;
(function(Aneox) {
    (function(Controllers) {
        'use strict';

        var BureauEtude = (function() {

            var self;

            function BureauEtude(animations) {
                self = this;
                self.timeline = animations.timeline;
            }

            BureauEtude.prototype.enter = function() {
                self.timeline.set('#bureau-etude, #bureau-etude section', { display: 'block', opacity: 0 });
                self.timeline.set('#bureau-etude-mast', { opacity: 1 });
                self.timeline.to('#bureau-etude', 0.5, { opacity: 1 });
            };

            BureauEtude.prototype.leave = function() {
                self.timeline.to('#bureau-etude', 0.5, { display: 'none', opacity: 0 });
                self.timeline.call(self.endLeave);
            };

            BureauEtude.prototype.handleWindowResize = function() {
            };
            
            BureauEtude.prototype.endLeave = function () {
                Aneox.App.prototype.resetScroll();
            };

            return BureauEtude;
        })();

        Controllers.BureauEtude = BureauEtude;
    })(Aneox.Controllers || (Aneox.Controllers = {}));
})(Aneox || (Aneox = {}));