var Aneox;
(function(Aneox) {
    (function(Controllers) {
        'use strict';

        var Expertise = (function() {

            var self;

            function Expertise(animations) {
                self = this;
                self.timeline = animations.timeline;
            }

            Expertise.prototype.enter = function(nodeId) {
                
                self.activeNodeId = nodeId;
               
               Expertise.prototype.setMenu(nodeId);

                self.timeline.set('#expertise, #expertise section', { display: 'block', opacity: 0 });
                self.timeline.set('#expertise section', { display: 'none', opacity: 0 });
                self.timeline.set('#expertise-' + self.activeNodeId, { display: 'block', opacity: 1 });
                /*self.timeline.call(function() {
                    TweenMax.fromTo('#expertise-' + self.activeNodeId + ' .background:eq(0)', 10, { scale: 1.05 }, { scale: 1 });
                });*/
                self.timeline.to('#expertise', 0.5, { opacity: 1 });
            };

            Expertise.prototype.leave = function() {
                self.timeline.to('#expertise', 0.5, { display: 'none', opacity: 0 });
                $('#main-menu-expertise a, #expertise-bullet-menu a').removeClass('active');
                $('#mobile-menu-expertise a').parent().removeClass('active');
                self.timeline.call(self.endLeave);
            };
            
            Expertise.prototype.endLeave = function () {
                Aneox.App.prototype.resetScroll();
            };
            
            Expertise.prototype.swap = function(nodeId){
                if (self.activeNodeId === nodeId) return;
                
                Expertise.prototype.showSection(nodeId);
            }
            
            Expertise.prototype.setMenu = function(nodeId){
                $('#main-menu-expertise a, #expertise-bullet-menu a').removeClass('active');
                $('#mobile-menu-expertise a').parent().removeClass('active');
                switch (nodeId) {
                    case 'construction':
                        $('#main-menu-expertise a:eq(0), #domain-bullet-menu a:eq(0)').addClass('active');
                        $('#mobile-menu-expertise a:eq(0)').parent().addClass('active');
                        break;
                    case 'extensions':
                        $('#main-menu-expertise a:eq(1), #domain-bullet-menu a:eq(1)').addClass('active');
                        $('#mobile-menu-expertise a:eq(1)').parent().addClass('active');
                        break;
                    case 'restauration':
                        $('#main-menu-expertise a:eq(2), #domain-bullet-menu a:eq(2)').addClass('active');
                        $('#mobile-menu-expertise a:eq(2)').parent().addClass('active');
                        break;
                    case 'rehabilitation':
                        $('#main-menu-expertise a:eq(3), #domain-bullet-menu a:eq(3)').addClass('active');
                        $('#mobile-menu-expertise a:eq(3)').parent().addClass('active');
                        break;
                }
            }

            Expertise.prototype.handleWindowResize = function() {
            };
            
            Expertise.prototype.showSection = function(nodeId) {
                if (self.activeNodeId === nodeId) return;

                Expertise.prototype.setMenu(nodeId);

                if (!self.activeNodeId) {
                    self.activeNodeId = nodeId;
                    return;
                }

                /*self.timeline.call(function() {
                    TweenMax.fromTo('#expertise-' + nodeId + ' .background:eq(0)', 10, { scale: 1.05 }, { scale: 1 });
                });*/
                self.timeline.set('#expertise-' + nodeId, { display: 'block', opacity: 0 });
                self.timeline.to('#expertise-' + self.activeNodeId, 0.5, { display: 'none', opacity: 0 });
                self.timeline.call(self.endLeave);
                self.timeline.to('#expertise-' + nodeId, 0.5, { opacity: 1 });

                self.activeNodeId = nodeId;
            };

            return Expertise;
        })();

        Controllers.Expertise = Expertise;
    })(Aneox.Controllers || (Aneox.Controllers = {}));
})(Aneox || (Aneox = {}));