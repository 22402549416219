var Aneox;
(function(Aneox) {
    (function(Controllers) {
        'use strict';

        var Legal = (function() {

            var self;

            function Legal(animations) {
                self = this;
                self.timeline = animations.timeline;
                
               
            }

            Legal.prototype.enter = function() {
                self.timeline.set('#legal, #legal section', { display: 'block', opacity: 0 });
                self.timeline.set('#legal-mast', { opacity: 1 });
                self.timeline.to('#legal', 0.5, { opacity: 1 });
            };

            Legal.prototype.leave = function() {
                self.timeline.to('#legal', 0.5, { display: 'none', opacity: 0 });
                self.timeline.call(self.endLeave);
            };

            Legal.prototype.handleWindowResize = function() {
            };
            
            Legal.prototype.endLeave = function () {
                Aneox.App.prototype.resetScroll();
            };

            return Legal;
        })();

        Controllers.Legal = Legal;
    })(Aneox.Controllers || (Aneox.Controllers = {}));
})(Aneox || (Aneox = {}));