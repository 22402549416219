var Aneox;
(function(Aneox) {
    'use strict';

    var App = (function() {

        var self;

        var isTouchDevice;

        var currentLocale;
        var currentNode;
        var currentController;

        function App(fonts, locale, router, analytics, homeController, bhfController, philosophieController, expertiseController, bureauetudeController, savoirfaireController, realisationsController, contactController, legalController) {
            self = this;

            self.fonts = fonts;
            self.locale = locale;
            self.router = router;
            self.analytics = analytics;
            self.controllers = {
                home: homeController,
                bhf: bhfController,
                philosophie: philosophieController,
                expertise: expertiseController,
                bureauetude: bureauetudeController,
                savoirfaire: savoirfaireController,
                realisations: realisationsController,
                contact: contactController,
                legal: legalController
            };

                lightbox.option({
                    'resizeDuration': 200,
                    'wrapAround': true,
                    'alwaysShowNavOnTouchDevices': true,
                    'albumLabel': "Image %1 sur %2"
                })

           var md = new MobileDetect(window.navigator.userAgent);
            Aneox.Data.Config.runtime = {
                isTouchDevice: 'ontouchstart' in document.documentElement,
                mobile: md.mobile(),
                supportsScrollEvents: !md.mobile() || (md.is('iOS') && md.is('Safari') && md.version('Safari') > 8)
            };

            lazySizesConfig.preloadAfterLoad = true;


            self.router.bind('pageChange', function(path, node, nodeLocale) {
                if (_.get(currentNode, 'id') === node.id) return;

                self.locale.setLocale(nodeLocale.locale);

                var rootNodeId, controllerId;
                
                // Enter/Leave animations
                if (_.get(currentNode, 'id') !== node.id) {
                    switch (_.get(currentNode, 'type')) {
                        case 'basic-page':
                            self.controllers[currentNode.id].leave();
                            break;
                        case 'savoir-page':
                            if (node.type === 'savoir-page') break;
                            self.controllers.savoirfaire.leave();
                            break;
                        case 'expertise-page':
                            if (node.type === 'expertise-page') break;
                            self.controllers.expertise.leave();
                            break;
                        case 'realisations-page':
                            if (node.type === 'realisations-page') break;
                            self.controllers.realisations.leave();
                            break;
                    }
                    
                    
                    // Update active menu item
                    $('#main-menu ul li, #mobile-menu-layer ul li').removeClass('active');
                    
                    

                    switch (node.type) {
                        case 'savoir-page':
                            if (_.get(currentNode, 'type') !== 'savoir-page') self.controllers.savoirfaire.enter(node.id);
                            else self.controllers.savoirfaire.swap(node.id);
                            rootNodeId = 'savoirfaire';
                            controllerId = 'savoirfaire';
                            break;
                        case 'expertise-page':
                            if (_.get(currentNode, 'type') !== 'expertise-page') self.controllers.expertise.enter(node.id);
                            else self.controllers.expertise.swap(node.id);
                            rootNodeId = 'expertise';
                            controllerId = 'expertise';
                            break;
                        case 'realisations-page':
                            if (_.get(currentNode, 'type') !== 'realisations-page') self.controllers.realisations.enter(node.id);
                            else self.controllers.realisations.swap(node.id);
                            rootNodeId = 'realisations';
                            controllerId = 'realisations';
                            break;
                        default:
                            self.controllers[node.id].enter();
                            rootNodeId = node.id;
                            controllerId = node.id;
                            break;
                    }
                    
                    switch(node.type){
                        case 'expertise-page':
                            self.closeSavoirfaireSubMenu();
                            self.openExpertiseSubMenu();
                        break;
                        case 'savoir-page':
                            self.closeExpertiseSubMenu();
                            self.openSavoirfaireSubMenu();
                        break;
                        default:
                            self.closeExpertiseSubMenu();
                            self.closeSavoirfaireSubMenu();
                        break;
                    }
                }
                
                $('#main-menu ul li a[router-href="' + rootNodeId + '"]').parent().addClass('active');
                    $('#mobile-menu-layer ul li a[router-href="' + rootNodeId + '"]:eq(0)').parent().addClass('active');
                    
                    if(node.type === "savoir-page"){
                    $('#main-menu ul li a[router-href="grosoeuvre"]').parent().addClass('active');
                    $('#mobile-menu-layer ul li a[router-href="grosoeuvre"]:eq(0)').parent().addClass('active');  
                    } 
                    if(node.type === "expertise-page"){
                    $('#main-menu ul li a[router-href="construction"]').parent().addClass('active');
                    $('#mobile-menu-layer ul li a[router-href="construction"]:eq(0)').parent().addClass('active');  
                    } 
                    if(node.type === "realisations-page") $('#main-menu ul li a[router-href="realisations"], #mobile-menu-layer ul li a[router-href="realisations"]').parent().addClass('active');
                    // Clear domain submenu
                
                //App.prototype.resetScroll();

                
                
                

                currentNode = node;
                currentController = self.controllers[controllerId];
            });

            self.locale.bind('localeChange', function(locale) {
                if (currentLocale === locale) return;

                // Update active language menu item
                $('#main-menu .language a').removeClass('active');
                $('#main-menu .language a[router-locale="' + locale + '"]').addClass('active');

                currentLocale = locale;

                setTimeout(handleWindowResize);
            });

            // Window resize handling
            var handleWindowResize = function() {
                var windowHeight = $(window).height();

                _.forEach($('[screen-height]'), function(element) {
                    var heightElement = $(element);
                    var topMargin = _.parseInt(heightElement.css('margin-top'));
                    var bottomMargin = _.parseInt(heightElement.css('margin-top'));
                    var height = windowHeight - topMargin - bottomMargin;

                    heightElement.innerHeight(height);
                });

                //$('#main-menu-domain-cursor').css('left', $('#main-menu li a[router-href="domain"]').offset().left - 20 + $('#main-menu li a[router-href="domain"]').innerWidth() * 0.5 - 10);
                //$('#main-menu-style-cursor').css('left', $('#main-menu li a[router-href="style"]').offset().left - 20 + $('#main-menu li a[router-href="style"]').innerWidth() * 0.5 - 10);

                if (currentController && currentController.handleWindowResize) currentController.handleWindowResize.call(currentController);
            };

            var throttledWindowResizeHandler = _.throttle(handleWindowResize, 500, { leading: false, trailing: true });
            $(window).resize(throttledWindowResizeHandler);
            Visibility.change(throttledWindowResizeHandler);
            self.fonts.bind('fontsActive', handleWindowResize);
            handleWindowResize();

            // Scroll handling
            $(window).on('scroll', function (event) {
                //App.prototype.swapMenu();
            });

            // Wheel handling
            // $(window).on('mousewheel', function (event) {

            // });

            // Touch handling
            if (Aneox.Data.Config.runtime.isTouchDevice) {
                // var mc = new Hammer.Manager(document.body);
                // mc.add(new Hammer.Swipe({ direction: Hammer.DIRECTION_HORIZONTAL }));
                // mc.on('swipeleft swiperight', function (event) {
                // });
            }

            // Keyboard handling
            //   $(window).keydown(function (event) {
            //   });

            if (Cookies.get('cookies') !== 'true') {
                TweenMax.fromTo('#cookies', 0.5, { opacity: 0, y: -50, display: 'block' }, { opacity: 1, y: 0 });
            }

            if (Cookies.get('splashscreen') !== 'true') {
                var now = new Date();
                now.setFullYear(now.getFullYear() - 18);
                var nowText = now.toISOString().substring(0, 10).split('-');
                $('#splashscreen-mast .date').text(nowText[2] + '/' + nowText[1] + '/' + nowText[0]);
                TweenMax.set('#splashscreen', { display: 'block', opacity: 1 });
                TweenMax.fromTo('#splashscreen-mast', 0.5, { display: 'block', opacity: 0 }, { opacity: 1 });
            }
            
        };
        
        App.prototype.resetScroll = function(){
            var scrollTopElement = /WebKit/.test(navigator.userAgent) ? $(document.body) : $("html");
            scrollTopElement.scrollTop(0);
        }
        
        App.prototype.swapMenu = function(){
                
                var scrollTopElement = /WebKit/.test(navigator.userAgent) ? $(document.body) : $("html");
                
                if (scrollTopElement.scrollTop() > 180) {
                    if(this.headerNavVisibility !== true){
                        TweenMax.killTweensOf("#header-nav");
                        TweenMax.to("#header-nav", 0.5, { y: 70, force3D: true });
                        this.headerNavVisibility = true;
                    }
                }else{
                    if (this.headerNavVisibility === true) {
                        TweenMax.killTweensOf("#header-nav");
                        TweenMax.to("#header-nav", 0.5, { y: 0, force3D: true });
                        this.headerNavVisibility = false;
                    }
                }
            }
            
            //App.prototype.swapMenu();
            
            
            App.prototype.toggleNavMenu = function () {
                TweenMax.killTweensOf('#mobile-menu-layer');

                if (!self.navMenuVisibility) {
                
                    $('body').css('overflow-y','hidden');
                    TweenMax.set('#mobile-menu-layer', { display: 'block', height: '100%' });
                    TweenMax.from('#mobile-menu-layer', 0.5, { height: 0 });
                    TweenMax.to('#mobile-menu-layer ul', 1, { opacity: 1 });
                    
                } else {
                    TweenMax.to('#mobile-menu-layer ul', 0.5, { opacity: 0 });
                    TweenMax.to('#mobile-menu-layer', 1, { height: 0, display: 'none' });
                    $('body').css('overflow-y','scroll');
                    
                    //TweenMax.set('#mobile-menu-layer', { display: 'none' });
                    
                }

                self.navMenuVisibility = !self.navMenuVisibility;
            };

        App.prototype.hideNavMenu = function () {
                if (!self.navMenuVisibility) return;

                TweenMax.killTweensOf('#nmobile-menu-layer');

                TweenMax.to('#mobile-menu-layer', 0.5, { height: 0, display: 'none' });
                TweenMax.set('#nav-menu-background', { display: 'none' });

                self.navMenuVisibility = !self.navMenuVisibility;
            }
        
        App.prototype.mainMenuMouseEnter = function(event, section) {
            if (Aneox.Data.Config.runtime.mobile) return;

            switch (section) {
                case 'expertise':
                    self.closeSavoirfaireSubMenu(event);
                    self.openExpertiseSubMenu(event);
                    break;
                case 'savoirfaire':
                    self.closeExpertiseSubMenu(event);
                    self.openSavoirfaireSubMenu(event);
                    break;
                default:
                    self.closeExpertiseSubMenu(event);
                    self.closeSavoirfaireSubMenu(event);
                    break;
            }
        };

        App.prototype.mainMenuMouseLeave = function(event) {
            if (Aneox.Data.Config.runtime.mobile) return;

            switch(_.get(currentNode, 'type')){
                case 'savoir-page':
                    self.closeExpertiseSubMenu(event);
                    self.openSavoirfaireSubMenu();
                break;
                case 'expertise-page':
                    self.closeSavoirfaireSubMenu(event);
                    self.openExpertiseSubMenu();
                break;
                default:
                    self.closeExpertiseSubMenu(event);
                    self.closeSavoirfaireSubMenu(event);
                break;
            }
            
        };

        App.prototype.mainMenuClick = function(event) {
            if (Aneox.Data.Config.runtime.mobile) return;
            
            self.closeExpertiseSubMenu(event);
            self.closeSavoirfaireSubMenu(event);
        };
        
        App.prototype.openExpertiseSubMenu = function(event) {
            if (self.expertiseMenuVisibility) return;
            
            if(_.get(currentNode, 'type') === "savoir-page") App.prototype.closeSavoirfaireSubMenu("switch");
            
            self.expertiseMenuVisibility = true;
            
            var headerHeight = $('header').height();
            var newHeaderHeight = headerHeight + 40;
            
            //TweenMax.killChildTweensOf('#main-menu');
            TweenMax.fromTo('#main-menu', 0.25, { height: 100 }, { height: 120, delay: 0.175 });
            TweenMax.fromTo('#main-menu-expertise-wrapper', 0.25, { display: 'block', height: 0 }, { height: 50, delay: 0.175 });
        };
           
        App.prototype.closeExpertiseSubMenu = function(event) {
            
            if(event !== "closesub"){
                if ((!self.expertiseMenuVisibility) && event !== "switch") return;    
            }
            self.expertiseMenuVisibility = false;
            
            
            //TweenMax.killChildTweensOf('#main-menu');
            TweenMax.fromTo('#main-menu-expertise-wrapper', 0.25, { height: 50 }, { height: 0, display: 'none' });
            TweenMax.fromTo('#main-menu', 0.25, { height: 120 }, { height: 100});
            
        };
        
        App.prototype.openSavoirfaireSubMenu = function(event) {
            if (self.SavoirfaireMenuVisibility) return;
            
            if(_.get(currentNode, 'type') === "expertise-page") App.prototype.closeExpertiseSubMenu("switch");
            
            self.SavoirfaireMenuVisibility = true;

            //TweenMax.killChildTweensOf('#main-menu');
            TweenMax.fromTo('#main-menu', 0.25, { height: 100 }, { height: 120, delay: 0.175 });
            TweenMax.fromTo('#main-menu-savoirfaire-wrapper', 0.25, { display: 'block', height: 0 }, { height: 50, delay: 0.175 });
        };
           
        App.prototype.closeSavoirfaireSubMenu = function(event) {
            
            if(event !== "closesub"){
                if ((!self.SavoirfaireMenuVisibility) && event !== "switch") return;    
            }
            
            self.SavoirfaireMenuVisibility = false;

            //TweenMax.killChildTweensOf('#main-menu');
            TweenMax.fromTo('#main-menu-savoirfaire-wrapper', 0.25, { height: 198 }, { height: 0, display: 'none' });
            TweenMax.fromTo('#main-menu', 0.25, { height: 120 }, { height: 100});
        };

        /*App.prototype.acceptCookies = function() {
            Cookies.set('cookies', 'true');
            TweenMax.to('#cookies', 0.5, { opacity: 0, y: -50, display: 'none' });
        };

        App.prototype.acceptSplash = function() {
            Cookies.set('splashscreen', 'true');
            TweenMax.to('#splashscreen', 0.5, { opacity: 0, display: 'none' });
        };

        App.prototype.refuseSplash = function() {
            window.location.href = 'https://google.fr';
        };

        App.prototype.mainMenuMouseEnter = function(event, section) {
            if (Aneox.Data.Config.runtime.mobile) return;

            switch (section) {
                case 'domain':
                    self.openDomainSubMenu(event);
                    self.closeStyleSubMenu(event);
                    break;
                case 'style':
                    self.openStyleSubMenu(event);
                    self.closeDomainSubMenu(event);
                    break;
            }
        };

        App.prototype.mainMenuMouseLeave = function(event) {
            if (Aneox.Data.Config.runtime.mobile) return;

            self.closeDomainSubMenu(event);
            self.closeStyleSubMenu(event);
        };

        App.prototype.mainMenuClick = function(event, section) {
            if (Aneox.Data.Config.runtime.mobile) return;

            self.closeDomainSubMenu(event);
            self.closeStyleSubMenu(event);
        };

        App.prototype.openDomainSubMenu = function(event) {
            if (self.domainMenuVisibility) return;
            self.domainMenuVisibility = true;

            //TweenMax.killChildTweensOf('#main-menu');
            if (!self.styleMenuVisibility) TweenMax.fromTo('#main-menu-background', 0.25, { display: 'block', y: 76 }, { y: 0 });
            TweenMax.fromTo('#main-menu-domain-wrapper', 0.25, { display: 'block', height: 0 }, { height: 198, delay: 0.175 });
        };

        App.prototype.closeDomainSubMenu = function(event) {
            if (!self.domainMenuVisibility) return;
            self.domainMenuVisibility = false;

            //TweenMax.killChildTweensOf('#main-menu');
            TweenMax.fromTo('#main-menu-domain-wrapper', 0.25, { height: 198 }, { height: 0, display: 'none' });
            if (!self.styleMenuVisibility) TweenMax.fromTo('#main-menu-background', 0.25, { y: 0 }, { y: 76, display: 'none', delay: 0.175 });
        };

        App.prototype.openStyleSubMenu = function(event) {
            if (self.styleMenuVisibility) return;
            self.styleMenuVisibility = true;

            //TweenMax.killChildTweensOf('#main-menu');
            if (!self.domainMenuVisibility) TweenMax.fromTo('#main-menu-background', 0.25, { display: 'block', y: 76 }, { y: 0 });
            TweenMax.fromTo('#main-menu-style-wrapper', 0.25, { display: 'block', height: 0 }, { height: 198, delay: 0.175 });
        };

        App.prototype.closeStyleSubMenu = function(event) {
            if (!self.styleMenuVisibility) return;
            self.styleMenuVisibility = false;

            //TweenMax.killChildTweensOf('#main-menu');
            TweenMax.fromTo('#main-menu-style-wrapper', 0.25, { height: 198 }, { height: 0, display: 'none' });
            if (!self.domainMenuVisibility) TweenMax.fromTo('#main-menu-background', 0.25, { y: 0 }, { y: 76, display: 'none', delay: 0.175 });
        };*/

        return App;
    })();

    Aneox.App = App;
})(Aneox || (Aneox = {}));