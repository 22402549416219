var Aneox;
(function(Aneox) {
    (function(Controllers) {
        'use strict';

        var BHF = (function() {

            var self;

            function BHF(animations) {
                self = this;
                self.timeline = animations.timeline;
            }

            BHF.prototype.enter = function() {
                self.timeline.set('#bhf, #bhf section', { display: 'block', opacity: 0 });
                self.timeline.set('#bhf-mast', { opacity: 1 });
                self.timeline.to('#bhf', 0.5, { opacity: 1 });
            };

            BHF.prototype.leave = function() {
               self.timeline.to('#bhf', 0.5, { display: 'none', opacity: 0 });
               self.timeline.call(self.endLeave);
            };

            BHF.prototype.handleWindowResize = function() {
            };
            
            BHF.prototype.endLeave = function () {
                Aneox.App.prototype.resetScroll();
            };

            return BHF;
        })();

        Controllers.BHF = BHF;
    })(Aneox.Controllers || (Aneox.Controllers = {}));
})(Aneox || (Aneox = {}));