var Aneox;
(function(Aneox) {
    (function(Controllers) {
        'use strict';

        var Home = (function() {

            var self;

            function Home(animations) {
                self = this;
                self.timeline = animations.timeline;                
            }

            Home.prototype.enter = function() {
                

                if(!self.slider){
                    console.log('enter1');
                    self.setGalerie();
                    self.slider = $("#home-references-slider").lightSlider({
                        item: 1,
                        autoWidth: false,
                        slideMove: 1, // slidemove will be 1 if loop is true
                        slideMargin: 0,
                        mode: "slide",
                        controls: false
                    });   
                    
                }

                self.timeline.set('#home, #home section', { display: 'block', opacity: 0 });
                self.timeline.set('#home-mast', { opacity: 1 });
                self.timeline.to('#home', 0.5, { opacity: 1});
                self.timeline.call(self.endEnter);
            };
            
            Home.prototype.endEnter = function(){
                self.slider.refresh();
            };

            Home.prototype.leave = function() {
                self.timeline.to('#home', 0.5, { display: 'none', opacity: 0 });
                self.timeline.call(self.endLeave);
            };

            Home.prototype.handleWindowResize = function() {
            };
            
            Home.prototype.endLeave = function () {
                Aneox.App.prototype.resetScroll();
            };
            
            Home.prototype.goToNext = function(){
                var scrollTopElement = /WebKit/.test(navigator.userAgent) ? $(document.body) : $("html");
                
                var homeOuverture = $('#home-ouverture');
                var homeOuvertureHeight = _.parseInt(homeOuverture.height());
                var windowHeight = $(window).height();
                
                var nextAnchorPos = homeOuvertureHeight - windowHeight;
                
                
                self.timeline.to('html, body', 0.5, {scrollTop: nextAnchorPos});
                
                
            };
            
            Home.prototype.setGalerie = function(){
                
                var dataGalerie = Aneox.Data.Data.home_galerie;
                var dataHtml = "";
                
                
                _.forEach(dataGalerie.slide, function(value){
                    var count = 0;
                    
                    dataHtml += "<li>";
                    
                    _.forEach(value.element, function(value2){
                        
                        
                        if(count == 0 || count == 2){
                            dataHtml += '<div class="row">';
                            
                        }
                        
                        dataHtml += '<div class="col-sm-6 col-xs-6 small-right-top-padding">';
                        dataHtml += '<a href="'+ value2.large +'" data-lightbox="group"><img data-src="'+ value2.thumb +'" class="lazyload img-responsive"/><img class="loupe lazyload" data-src="assets/interface/loupe.svg" width="40" height="40"/></a>';
                        dataHtml += '</div>';
                        
                        if(count == 1 || count == 3){
                            dataHtml += "</div>"
                        }
                            
                        count++;    
                    });
                    
                   
                    dataHtml += "</li>";
                    
                });
                
                $('#home-references-slider').html(dataHtml);
            };

            return Home;
        })();

        Controllers.Home = Home;
    })(Aneox.Controllers || (Aneox.Controllers = {}));
})(Aneox || (Aneox = {}));
