var Aneox;
(function(Aneox) {
    (function(Controllers) {
        'use strict';

        var SavoirFaire = (function() {

            var self;

            function SavoirFaire(animations) {
                self = this;
                self.timeline = animations.timeline;
            }

            SavoirFaire.prototype.enter = function(nodeId) {
                
                self.activeNodeId = nodeId;
                
                //if (!self.activeNodeId) self.activeNodeId = 'grosoeuvre';
                SavoirFaire.prototype.setMenu(nodeId);

                //$('#main-menu-savoirfaire a, #savoirfaire-bullet-menu a').removeClass('active');
                //$('#main-menu-savoirfaire a:eq(0), #savoirfaire-bullet-menu a:eq(0)').addClass('active');

                self.timeline.set('#savoirfaire, #savoirfaire section', { display: 'block', opacity: 0 });
                self.timeline.set('#savoirfaire section', { display: 'none', opacity: 0 });
                self.timeline.set('#savoirfaire-' + self.activeNodeId, { display: 'block', opacity: 1 });
                /*self.timeline.call(function() {
                    TweenMax.fromTo('#savoirfaire-' + self.activeNodeId + ' .background:eq(0)', 10, { scale: 1.05 }, { scale: 1 });
                });*/
                self.timeline.to('#savoirfaire', 0.5, { opacity: 1 });
            };

            SavoirFaire.prototype.leave = function() {
               self.timeline.to('#savoirfaire', 0.5, { display: 'none', opacity: 0 });
               $('#main-menu-savoirfaire a, #savoirfaire-bullet-menu a').removeClass('active');
               $('#mobile-menu-savoirfaire a').parent().removeClass('active');
               self.timeline.call(self.endLeave);
            };
            
            SavoirFaire.prototype.endLeave = function () {
                Aneox.App.prototype.resetScroll();
            };
            
            SavoirFaire.prototype.swap = function(nodeId){
                if (self.activeNodeId === nodeId) return;
                
                SavoirFaire.prototype.showSection(nodeId);
                
            }
            
            SavoirFaire.prototype.setMenu = function(nodeId){
                $('#main-menu-savoirfaire a, #savoirfaire-bullet-menu a').removeClass('active');
                $('#mobile-menu-savoirfaire a').parent().removeClass('active');
                switch (nodeId) {
                    case 'grosoeuvre':
                        $('#main-menu-savoirfaire a:eq(0), #domain-bullet-menu a:eq(0)').addClass('active');
                        $('#mobile-menu-savoirfaire a:eq(0)').parent().addClass('active');
                        break;
                    case 'assainissement':
                        $('#main-menu-savoirfaire a:eq(1), #domain-bullet-menu a:eq(1)').addClass('active');
                        $('#mobile-menu-savoirfaire a:eq(1)').parent().addClass('active');
                        break;
                    case 'charpente':
                        $('#main-menu-savoirfaire a:eq(2), #domain-bullet-menu a:eq(2)').addClass('active');
                        $('#mobile-menu-savoirfaire a:eq(2)').parent().addClass('active');
                        break;
                    case 'couverture':
                        $('#main-menu-savoirfaire a:eq(3), #domain-bullet-menu a:eq(3)').addClass('active');
                        $('#mobile-menu-savoirfaire a:eq(3)').parent().addClass('active');
                        break;
                }
            }
            
            SavoirFaire.prototype.showSection = function(nodeId) {
                if (self.activeNodeId === nodeId) return;
                
                SavoirFaire.prototype.setMenu(nodeId);

                if (!self.activeNodeId) {
                    self.activeNodeId = nodeId;
                    return;
                }

                /*self.timeline.call(function() {
                    TweenMax.fromTo('#savoirfaire-' + nodeId + ' .background:eq(0)', 10, { scale: 1.05 }, { scale: 1 });
                });*/
                self.timeline.set('#savoirfaire-' + nodeId, { display: 'block', opacity: 0 });
                self.timeline.to('#savoirfaire-' + self.activeNodeId, 0.5, { display: 'none', opacity: 0 });
                self.timeline.call(self.endLeave);
                self.timeline.to('#savoirfaire-' + nodeId, 0.5, { opacity: 1 });

                self.activeNodeId = nodeId;
            };

            SavoirFaire.prototype.handleWindowResize = function() {
            };

            return SavoirFaire;
        })();

        Controllers.SavoirFaire = SavoirFaire;
    })(Aneox.Controllers || (Aneox.Controllers = {}));
})(Aneox || (Aneox = {}));