var Aneox;
(function(Aneox) {
    (function(Controllers) {
        'use strict';

        var Contact = (function() {

            var self;

            function Contact(animations) {
                self = this;
                self.timeline = animations.timeline;
            }

            Contact.prototype.enter = function() {
                self.timeline.set('#contact, #contact section', { display: 'block', opacity: 0 });
                self.timeline.set('#contact-mast', { opacity: 1 });
                self.timeline.to('#contact', 0.5, { opacity: 1 });
            };

            Contact.prototype.leave = function() {
                self.timeline.to('#contact', 0.5, { display: 'none', opacity: 0 });
                self.timeline.call(self.endLeave);
            };

            Contact.prototype.handleWindowResize = function() {
                if (!Aneox.Data.Config.runtime.mobile){
                    var screenWidth = _.get(window, 'screen.width', 0);
                    var screenHeight = _.get(window, 'screen.height', 0);
                    var maxScreenSize = Math.max(screenWidth, screenHeight);
                    var screenOrientation = _.get(window, 'orientation', 0);
                    var isPortrait = screenOrientation === 0 || screenOrientation === 180 || screenOrientation === -180;
                    var windowHeight = $(window).height();
                    
                    var elementMenu = $('header');
                    var elementFooter = $('footer');
                    var menuHeight = _.parseInt(elementMenu.css('height'));
                    var footerHeight = _.parseInt(elementFooter.css('height'));
                    var contactHeight = windowHeight - menuHeight - footerHeight;
                    
                    //console.log(openingHeight);
                    
                    $('.img-full-page').height(contactHeight);   
                }
                
            };
            
            Contact.prototype.endLeave = function () {
                Aneox.App.prototype.resetScroll();
            };

            return Contact;
        })();

        Controllers.Contact = Contact;
    })(Aneox.Controllers || (Aneox.Controllers = {}));
})(Aneox || (Aneox = {}));