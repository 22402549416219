var Aneox;
(function(Aneox) {
    (function(Controllers) {
        'use strict';

        var Philosophie = (function() {

            var self;

            function Philosophie(animations) {
                self = this;
                self.timeline = animations.timeline;
            }

            Philosophie.prototype.enter = function() {
                self.timeline.set('#philosophie, #philosophie section', { display: 'block', opacity: 0 });
                self.timeline.set('#philosophie-mast', { opacity: 1 });
                self.timeline.to('#philosophie', 0.5, { opacity: 1 });
            };

            Philosophie.prototype.leave = function() {
                self.timeline.to('#philosophie', 0.5, { display: 'none', opacity: 0 });
                self.timeline.call(self.endLeave);
            };

            Philosophie.prototype.handleWindowResize = function() {
            };
            
            Philosophie.prototype.endLeave = function () {
                Aneox.App.prototype.resetScroll();
            };

            return Philosophie;
        })();

        Controllers.Philosophie = Philosophie;
    })(Aneox.Controllers || (Aneox.Controllers = {}));
})(Aneox || (Aneox = {}));