var Aneox;
(function(Aneox) {
    (function(Controllers) {
        'use strict';

        var Realisations = (function() {

            var self;

            function Realisations(animations) {
                self = this;
                self.timeline = animations.timeline;
                

            }

            Realisations.prototype.enter = function(nodeId) {
                
                self.activeNodeId = nodeId;
                
                $('#realisations-list li').removeClass('active');
                $('#realisations-list li[node="' + nodeId + '"]').addClass('active');
                
                self.timeline.set('#realisations, #realisations section', { display: 'block', opacity: 0 });
                self.timeline.set('#realisations-mast', { opacity: 1 });
                self.timeline.to('#realisations', 0.5, { opacity: 1 });
                
                self.timeline.call(self.populateRealisations.bind(self, nodeId));
            };

            Realisations.prototype.leave = function() {
               self.timeline.to('#realisations', 0.5, { display: 'none', opacity: 0 });
               self.timeline.call(self.endLeave);
            };
            
            Realisations.prototype.endLeave = function () {
                Aneox.App.prototype.resetScroll();
            };

            Realisations.prototype.handleWindowResize = function() {
            };
            
            Realisations.prototype.swap = function(nodeId){
                
                
                self.timeline.to('#realisations-gallerie', 0.5, { display: 'none', opacity: 0 });
                self.timeline.call(self.populateRealisations.bind(self, nodeId));
                self.timeline.set('#realisations-gallerie', { display: 'block', opacity: 0 });
                self.timeline.to('#realisations-gallerie', 0.5, { opacity: 1 });
            }
            
            Realisations.prototype.populateRealisations = function(realisationsId){
                
                $('#realisations-list li').removeClass('active');
                $('#realisations-list li[node="' + realisationsId + '"]').addClass('active');
                
                var dataHtml = '';
                
                if(realisationsId === "realisations"){
                    var dataRealisations = Aneox.Data.Data.realisations;
                   
                     
                    _.forEach(dataRealisations, function(value, key){
                    
                        
                        if(_.size(value.categorie) == 0){
                           
                            var i =0;
                            var nbElement = _.size(value.element);
                            
                            dataHtml +='<div class="col-sm-12 col-xs-12"><h2>'+ value.libelle +'</h2><hr></div>';
                            
                            _.forEach(value.element, function(value3){
                                
                                 if(i%2 == 0){
                                    dataHtml += '<div class="row">';
                                }  
                                
                                dataHtml +='<div class="col-sm-6 col-xs-6 element-realisation" >';
                                dataHtml +='<div class="wrapper-img-rea"><a data-lightbox="group" data-title="'+ value3.description +'" class="realisations-gallerie" href="'+ value3.image +'"><div data-bg="'+ value3.miniature +'" class="lazyload background-cover img-realisation" ><img class="loupe lazyload" data-src="assets/interface/loupe.svg" width="40" height="40"/></div></a></div>';
                                
                                if(!_.isEmpty(value3.description)){
                                    dataHtml +='<span>'+ value3.description +'</span><hr>';    
                                }else{
                                    dataHtml +='<span>&nbsp;</span><hr style="background-color:#ffffff;">'; 
                                }
                                
                                dataHtml +='</div>';
                                
                                if(i%2 != 0 || i == (nbElement-1)){
                                    dataHtml += '</div>';
                                }  
 
                                i++;
                                
                            });
                        }else{
                            
                            
                            
                            _.forEach(value.categorie, function(value2){
                                dataHtml +='<div class="col-sm-12 col-xs-12"><h2>'+ value2.libelle +'</h2><hr></div>';
                                var j = 0;
                                var nbElement = _.size(value2.element);
                                _.forEach(value2.element, function(value3){
                                    
                                     if(j%2 == 0){
                                        dataHtml += '<div class="row">';
                                    }  
                                    
                                    dataHtml +='<div class="col-sm-6 col-xs-6 element-realisation" >';
                                    dataHtml +='<div class="wrapper-img-rea"><a data-lightbox="group" data-title="'+ value3.description +'" class="realisations-gallerie" href="'+ value3.image +'"><div data-bg="'+ value3.miniature +'" class="lazyload background-cover img-realisation" ><img class="loupe lazyload" data-src="assets/interface/loupe.svg" width="40" height="40"/></div></a></div>';
                                    
                                    if(!_.isEmpty(value3.description)){
                                        dataHtml +='<span>'+ value3.description +'</span><hr>';    
                                    }else{
                                    dataHtml +='<span>&nbsp;</span><hr style="background-color:#ffffff;">'; 
                                }
                                    
                                    dataHtml +='</div>';
                                    
                                    if(j%2 != 0 || j == (nbElement-1)){
                                        dataHtml += '</div>';
                                    }  
 
                                   j++;
                                   
                                });
                               
                            });
                        }
                        
                        
            
                    });
                    
                    
                }else{
                    var dataRealisations = _.find(Aneox.Data.Data.realisations, {id: realisationsId});  
                    
                 
                    
                    if(_.size(dataRealisations.categorie) == 0){
                       
                            dataHtml +='<div class="col-sm-12 col-xs-12"><h2>'+ dataRealisations.libelle +'</h2><hr></div>';
                            
                            var j = 0;
                            var nbElement = _.size(dataRealisations.element);
                            _.forEach(dataRealisations.element, function(value3){
                                
                                if(j%2 == 0){
                                    dataHtml += '<div class="row">';
                                }  
                                
                                dataHtml +='<div class="col-sm-6 col-xs-6 element-realisation">';
                                dataHtml +='<div class="wrapper-img-rea"><a data-lightbox="group" data-title="'+ value3.description +'" class="realisations-gallerie " href="'+ value3.image +'"><div data-bg="'+ value3.miniature +'" class="lazyload background-cover img-realisation" ><img class="loupe lazyload" data-src="assets/interface/loupe.svg" width="40" height="40"/></div></a></div>';
                                
                                if(!_.isEmpty(value3.description)){
                                    dataHtml +='<span>'+ value3.description +'</span><hr>';    
                                }else{
                                    dataHtml +='<span>&nbsp;</span><hr style="background-color:#ffffff;">'; 
                                }
                                
                                dataHtml +='</div>';
                                
                               if(j%2 != 0  || j == (nbElement-1)){
                                    dataHtml += '</div>';
                                }  
 
                                j++;
                                
                            });
                        
                            
                    }else{
                        
                        
                        
                        
                        _.forEach(dataRealisations.categorie, function(value2){
                            dataHtml +='<div class="col-sm-12 col-xs-12"><h2>'+ value2.libelle +'</h2><hr></div>';
                            
                            var j = 0;
                            var nbElement = _.size(value2.element);
                            _.forEach(value2.element, function(value3){
                                
                            
                                  
                                if(j%2 == 0){
                                    dataHtml += '<div class="row">';
                                }    
                                
                            
                                dataHtml +='<div class="col-sm-6 col-xs-6 element-realisation" >';
                                dataHtml +='<div class="wrapper-img-rea"><a data-lightbox="group" data-title="'+ value3.description +'" class="realisations-gallerie " href="'+ value3.image +'"><div data-bg="'+ value3.miniature +'" class="lazyload background-cover img-realisation" ><img class="loupe lazyload" data-src="assets/interface/loupe.svg" width="40" height="40"/></div></a></div>';
                                
                                if(!_.isEmpty(value3.description)){
                                    dataHtml +='<span>'+ value3.description +'</span><hr>';    
                                }else{
                                    dataHtml +='<span>&nbsp;</span><hr style="background-color:#ffffff;">'; 
                                }
                                
                                dataHtml +='</div>';
                                
                                
                             
                                if(j%2 != 0  || j == (nbElement-1)){
                                    dataHtml += '</div>';
                                }  
 
                                j++;

                            });
                            
                        });
                    }
                    

                      
                }
                
                //var dataRealisations = _.find(Aneox.Data.Data.realisations);
                
                
         
                $('#realisations-data').html(dataHtml);
                
                
                
                
                
            }

            return Realisations;
        })();

        Controllers.Realisations = Realisations;
    })(Aneox.Controllers || (Aneox.Controllers = {}));
})(Aneox || (Aneox = {}));